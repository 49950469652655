import { map } from "lodash";
import { CSSProperties } from "react";

import { Person } from "@api";

import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { safeAs } from "@utils/maybe";
import { toInitials } from "@utils/names";

import { HStack } from "@ui/flex";

import { Icon, Props as IconProps } from ".";
import { ImageIcon } from "./image";

import person from "./person.module.css";
import core from "./styles.module.css";

const styles = {
  ...core,
  ...person,
};

export interface Props {
  person: Pick<Person, "avatar" | "name" | "email"> & {
    id?: string;
    fullName?: string;
  };
  online?: boolean;
  shape?: "circle" | "square";
  size?: IconProps["size"];
  className?: string;
  style?: CSSProperties;
}

export const PersonIcon = ({
  person,
  online,
  className,
  size = "default",
  style,
  shape = "circle",
}: Props) => (
  <ImageIcon
    url={person.avatar}
    className={cx(
      styles.icon,
      styles.person,
      size && styles[size],
      person.avatar && styles.image,
      online && styles.online,
      shape && styles[shape],
      className
    )}
  >
    {!person.avatar && (
      <span className={cx(styles.initials)}>
        {toInitials(
          person.name || safeAs<Person>(person)?.fullName || person.email || ""
        )}
      </span>
    )}
  </ImageIcon>
);

type StackProps = {
  people: Person[];
  onSelected?: (person: Person) => void;
  className?: string;
  style?: CSSProperties;
} & Pick<IconProps, "size"> &
  Pick<Props, "shape">;

export const PersonIconStack = ({
  people,
  shape,
  onSelected,
  size = "xlarge",
  className,
  style,
}: StackProps) => (
  <HStack
    gap={0}
    className={cx(styles.stack, styles[size], className)}
    style={style}
  >
    {map(people, (person) => (
      <Icon
        key={person.id}
        icon={<PersonIcon key={person.id} person={person} shape={shape} />}
        size={size}
        onClick={withHandle(() => onSelected?.(person))}
      />
    ))}
  </HStack>
);

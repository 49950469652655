import * as Graph from "@graph/types";

import { OneOrMany } from "@utils/array";
import { CalDate, ISODate, PointDate } from "@utils/date-fp";
import { Maybe } from "@utils/maybe";
import {
  Color,
  CustomData,
  EntityType,
  ID,
  Json,
  JsonObject,
  OptionsData,
  Orders,
  Ref,
  RefsData,
  RichText,
  SettingsData,
  Stamp,
  Stamps,
  Template,
  Timestamp,
} from "@utils/shared";
import { OrStar } from "@utils/wildcards";

import { Comment as PageComment, PageBlocks } from "./integrations/notion";

export * from "@utils/shared";

export type Vars = VariableDef[];

export type { PageComment };

export enum GraphError {
  NotFound = "NOT_FOUND",
  BadUserInput = "BAD_USER_INPUT",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  PermissionDenied = "PERMISSION_DENIED",
  BadAuthorization = "BAD_AUTHORIZATION",
}

// Update on new entity

export type Entity =
  | Task
  | Outcome
  | Project
  | Page
  | Campaign
  | Calendar
  | Content
  | Resource
  | Backlog
  | Roadmap
  | Sprint
  | Schedule
  | View
  | Team
  | Note
  | Meeting
  | Agenda
  | Action
  | Person
  | Process
  | Form
  | Request
  | Event
  | Pipeline
  | Workflow
  | WorkflowStep
  | KnowledgeBase
  | Company
  | Contact
  | Deal
  | Workspace;

export type EntityTypeMap = {
  [k in EntityType]: Extract<Entity, { source: { type: k } }>;
};

export type ExtractEntityType<T extends Entity> = T["source"]["type"];

export type HasSettings = Extract<Entity, { settings: Maybe<SettingsData> }>;
export type HasColor = Extract<Entity, { color: Maybe<Color> }>;
export type HasRefs = Extract<Entity, { refs: Maybe<RefsData> }>;
export type HasTimestamps = Extract<Entity, { stamps: Maybe<Stamps> }>;
export type HasViews = Extract<Entity, { views: Maybe<Ref[]> }>;
export type HasNotes = Extract<Entity, { refs: Maybe<RefsData> }>;
export type HasResources = Extract<Entity, { refs: Maybe<RefsData> }>;
export type HasFollowers = Extract<Entity, { refs: Maybe<RefsData> }>;
export type HasBody = Extract<Entity, { body: Maybe<RichText> }>;
export type HasBlocked = Extract<Entity, { blocked: Maybe<boolean> }>;
export type HasRepeat = HasRefs;
export type HasDates = Extract<Entity, { start: Maybe<ISODate> }>;
export type HasLocation = Extract<Entity, { location: string }>;
export type HasTemplate = Extract<Entity, { template: Maybe<Template> }>;
export type HasArchivedAt = Extract<Entity, { archivedAt: Maybe<PointDate> }>;
export type HasDeletedAt = Extract<Entity, { deletedAt?: Maybe<PointDate> }>;
export type HasCreatedAt = Extract<Entity, { createdAt?: Maybe<PointDate> }>;
export type HasCreatedBy = Extract<Entity, { createdBy?: Maybe<Ref> }>;
export type HasCover = Extract<Entity, { cover: Maybe<URL> }>;
export type HasAssigned = Extract<Entity, { assigned: Maybe<Ref> }>;
export type HasOwner = Extract<Entity, { owner: Maybe<Ref> }>;
export type HasStatus = Extract<Entity, { status: Maybe<Status> }>;
export type HasTitle = Extract<Entity, { title: Maybe<string> }>;
export type HasCode = Extract<Entity, { code: Maybe<string> }>;
export type HasName = Extract<Entity, { name: Maybe<string> }>;
export type HasIcon = Extract<Entity, { icon: Maybe<string> }>;
export type HasOrder = Extract<Entity, { order: Maybe<number> }>;
export type HasOrders = Extract<Entity, { orders: Maybe<Orders> }>;

export type { Json, JsonArray, JsonObject, JsonValue } from "@utils/shared";

export interface SourceID {
  id: ID;
  source: Integration;
}

export interface IntegrationIDs {
  figma?: ID;
  asana?: ID;
  github?: ID;
  notion?: ID;
  slack?: ID;
  google?: ID;
  traction?: ID;
  zoom?: ID;
}

export interface DatabaseID<T extends EntityType = EntityType> {
  scope: ID;
  type: T;
  source?: Integration;
}

export type ScopedRef = Ref & {
  source: DatabaseID;
};

export type FetchOptions = {
  fetch?: boolean;
  since?: PointDate;
  skip?: number;
  limit?: number;
  templates?: boolean;
  archived?: boolean;
};

type SearchMode = "contains" | "fuzzy";

export type SearchOptions = FetchOptions & {
  method?: SearchMode;
};

export enum ErrorHandle {
  Discard = "discard",
  Retry = "retry",
  Ignore = "ignore",
  Fail = "fail",
}

export enum ErrorCode {
  NotFound = "not_found",
  Unauthorized = "unauthorized",
  Forbidden = "forbidden",
  BadRequest = "bad_request",
  InternalServerError = "internal_server_error",
  Unknown = "unknown",
  Deleted = "deleted",
}

export type Error = {
  message: string;
  code: ErrorCode;
  handle: ErrorHandle;
};

export enum Integration {
  Google = "google",
  Notion = "notion",
  Slack = "slack",
  Zoom = "zoom",
  Asana = "asana",
  Traction = "traction",
}

export type AuthToken = {
  access: string;
  refresh?: string;
  expiresAt?: PointDate;
};

export type IntegrationAuth =
  | {
      source: Integration.Traction;
      token: string;
      workspaceId: ID;
      workspaceName: string;
      workspaceIcon: Maybe<string>;
      owner: { workspace: true } | Person;
    }
  | {
      source: Integration.Notion;
      token: string;
      workspaceId: ID;
      workspaceName: string;
      workspaceIcon: Maybe<string>;
      botId: ID;
      userId: ID;
      owner: { workspace: true } | Person;
    }
  | {
      source: Integration.Slack;
      botToken: string;
      userToken: string;
      user?: Person;
    };

export type Workspace = {
  id: ID;
  name: string;
  icon: Maybe<string>;
  setupAt?: PointDate;
  settings: Maybe<SettingsData>;
  source: DatabaseID<"workspace">;
  fetchedAt: Maybe<ISODate>;
  createdAt: PointDate;
  updatedAt: PointDate;
};

export type WorkspaceConfig = {
  token: string;
  auths: Graph.Auths;
  workspace: Maybe<Workspace>;
  user: Person;
};

export type PropertyFormat =
  // Date formattings
  | "month"
  | "week"
  | "day"
  | "quarter"
  | "year"
  | "time_ago"
  | "time"
  // Status formattings
  | "group"
  // Number formattings
  | "float"
  | "int"
  | "int_commas"
  | "dollar"
  | "percent"
  // Duration formats
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  // Location formattings
  | "team"
  | "root"
  | "parent";

export type ViewLayout =
  | "list"
  | "card"
  | "table"
  | "calendar"
  | "timeline"
  | "canvas"
  | "browser"
  // Deprecated lol
  | "default"
  | "timelines"
  | "columns"
  | "board";

export type ViewGrouping =
  | "none"
  | "rows"
  | "columns"
  | "columns_rows"
  | "quadrants";

export type SelectOption = {
  id?: string;
  name?: string;
  color?: "default" | Color;
};

// TODO: Rename status groups :grimace:
// export type StatusGroup = "draft" | "ready" | "in-progress" | "done";
export type StatusGroup = "planning" | "not-started" | "in-progress" | "done";

export type Status = SelectOption & {
  group?: StatusGroup;
  blocked?: boolean;
};

export type { RichText } from "@utils/shared";

export type ProjectType = "none" | "sprint" | "editorial" | "simple" | string;

export type PropertyValue = {
  text?: string;
  rich_text?: RichText;
  number?: number;
  status?: Status;
  select?: SelectOption;
  date?: ISODate;
  boolean?: boolean;
  formula?: string;
  multi_select?: SelectOption[];
  relation?: Ref;
  relations?: Ref[];
  checklist?: RichText;
  link?: Link;
  links?: Link[];
  json?: Json;
  property?: PropertyRef | SortByProp;
  properties?: PropertyRef[] | SortByProp[];
  stamp?: Stamp;

  // Deprecated from Notion
  person?: Ref[];
  email?: string;
  phone?: string;
  title?: string;
  url?: string;
};

export type PropertyValues = {
  [P in keyof PropertyValue]?: FlattenType<
    Exclude<PropertyValue[P], undefined>
  >[];
};

export type PropertyType = keyof PropertyValue;

export type PropertyRef<
  E extends Entity = Entity,
  T = PropertyType,
  P = keyof E | string
> = {
  field: P;
  type: T;
};

export type PropertyValueRef<
  E extends Entity = Entity,
  T extends PropertyType = PropertyType,
  P = keyof E | string
> = {
  field: P;
  type: T;
  value: PropertyValue;
  def?: PropertyDef<E, T>;
};
// & { type: "multi_select"; value: Pick<PropertyValue, "multi_select"> };

export type VariableDef = {
  field: PropertyRef<Entity, PropertyType>["field"];
  type: PropertyType;
  value: PropertyValue;
} & Partial<InlinePropertyDef>;

export enum PropertyVisibility {
  HideAlways = "hide_always",
  HideEmpty = "hide_empty",
  ShowAlways = "show_always",
  Hidden = "hidden",
}

export enum DisplayAs {
  Property = "property",
  Section = "section",
  Pane = "pane",
}

export enum AiAssist {
  Off = "off",
  Auto = "auto",
  Manual = "manual",
}

export enum CalendarPeriod {
  Month = "month",
  Week = "week",
  Day = "day",
}

export type ViewOptions = {
  // Whether this view should show templates or not
  templates?: boolean;

  // Calendar Options
  period?: CalendarPeriod;
  weekends?: boolean;

  // Grouping Options
  collapse?: boolean;

  // Nested work options
  hideNested?: boolean;

  // Show sub-work (e.g. subtasks)
  showSubs?: boolean;

  // Field options
  hideEmptyFields?: boolean;

  // Triage empty groups
  triageEmpty?: boolean;

  // Field options
  canvasBy?: Maybe<PropertyRef>;
};

export type PropertyDefOptions = {
  // Relation Options
  references?: OrStar<OneOrMany<EntityType>>;
  templates?: "always" | "either" | "match" | "never";
  sync?: string;
  hierarchy?: "parent" | "child";

  // Date options
  mode?: "calendar" | "point";

  // Number options
  format?: Maybe<PropertyFormat>;
};

export type PropertyDef<
  E extends Entity = Entity,
  P extends PropertyType = PropertyType
> = {
  entity: EntityType[];
  type: P;
  field: string;
  scope: string;
  values?: PropertyValues;
  label?: Maybe<string>;
  description?: Maybe<string>;
  format?: Maybe<PropertyFormat>;
  order?: Maybe<number>;
  assist?: AiAssist;
  options?: Maybe<PropertyDefOptions>;
  visibility: PropertyVisibility;
  displayAs: DisplayAs;
  locked: boolean;
  readonly: boolean;
  system?: boolean;
  createdAt?: PointDate;
  updatedAt?: PointDate;
  deletedAt?: Maybe<PointDate>;
};

export type InlinePropertyDef = Omit<
  PropertyDef<Entity, PropertyType>,
  | "entity"
  | "type"
  | "field"
  | "scope"
  | "order"
  | "createdAt"
  | "updatedAt"
  | "deletedAt"
>;

export type StatusValueRef = PropertyValueRef<Entity, "status">;

export type RelationRef = {
  id: string;
  name?: string;
  entity?: EntityType;
};

export type EntityRef = {
  id: ID;
  name?: string;
  source: DatabaseID;
};

export type URL = string;
export type Link = {
  id?: ID;
  text?: string;
  url: URL;
  icon?: string;
  pinned?: boolean;
};

export type FileMeta = {
  name: string;
  path: string;
  url: string;
  mimeType?: string;
};

export interface CoreDates {
  fetchedAt: Maybe<PointDate>;
  createdAt: PointDate;
  createdBy: Maybe<PersonRef | Ref>;
  updatedAt: PointDate;
  updatedBy: Maybe<PersonRef | Ref>;
  deletedAt?: PointDate;
  deletedBy?: PersonRef | Ref;
}

export type CoreEntity<T extends EntityType> = CoreDates & {
  id: ID;
  refs: Maybe<RefsData>;
  custom: Maybe<CustomData>;
  stamps: Maybe<Stamps>;
  settings?: Maybe<SettingsData>;
  source: DatabaseID<T>;
};

export type Locationable = {
  location: string;
};

export type Templatable = {
  template: Maybe<Template>;
};

export type Archivable = {
  archivedAt: Maybe<PointDate>;
  archivedBy: Maybe<PersonRef | Ref>;
};

export enum PersonRole {
  Owner = "owner",
  Admin = "admin",
  Member = "member",
  Guest = "guest",
}
export type Person = CoreEntity<"person"> &
  Archivable & {
    id: ID;
    name?: string;
    fullName?: string;
    role: SelectOption;
    avatar?: string;
    email?: string;
    aka?: IntegrationIDs;

    teams: Maybe<Ref[]>;
    views: Maybe<Ref[]>;
    source: DatabaseID<"person">;
  };

export interface PersonRef {
  id: ID;
  source: Integration;
  name?: string;
  avatar?: string;
  email?: string;
}

export type User = Person;

export type Project = CoreEntity<"project"> &
  Archivable &
  Templatable &
  Locationable & {
    notionId: Maybe<ID>;
    source: DatabaseID<"project">;
    icon: Maybe<URL>;
    color: Maybe<Color>;
    cover: Maybe<URL>;
    orders: Maybe<Orders>;
    name: Maybe<string>;
    owner: Maybe<Ref>;
    summary: Maybe<RichText>;
    body: Maybe<RichText>;
    status: Maybe<Status>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    views: Maybe<Ref[]>;
    // TODO: REMOVE
    pinned: Maybe<boolean>;
    // TODO: REMOVE
    type: Maybe<ProjectType>;
    thread: Maybe<URL>;
    links: Maybe<Link[]>;
  };

export type Campaign = CoreEntity<"campaign"> &
  Templatable &
  Archivable &
  Locationable & {
    icon: Maybe<URL>;
    color: Maybe<Color>;
    code: Maybe<string>;
    orders: Maybe<Orders>;
    name: Maybe<string>;
    summary: Maybe<RichText>;
    body: Maybe<RichText>;
    owner: Maybe<Ref>;
    status: Maybe<Status>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
  };

export type Calendar = CoreEntity<"calendar"> &
  Templatable &
  Archivable &
  Locationable & {
    icon: Maybe<URL>;
    orders: Maybe<Orders>;
    name: Maybe<string>;
    owner: Maybe<Ref>;
    views: Maybe<Ref[]>;
  };

export type Content = CoreEntity<"content"> &
  Archivable &
  Templatable &
  Locationable & {
    icon: Maybe<URL>;
    orders: Maybe<Orders>;
    name: Maybe<string>;
    code: Maybe<string>;
    summary: Maybe<RichText>;
    owner: Maybe<Ref>;
    body: Maybe<RichText>;
    status: Maybe<Status>;
    publish: Maybe<PointDate>;
  };

export type Backlog = CoreEntity<"backlog"> &
  Archivable &
  Templatable &
  Locationable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    orders: Maybe<Orders>;
    inbox: Maybe<Ref>;
    views: Maybe<Ref[]>;
    fields: Maybe<SortByProp[]>;
  };

export type Roadmap = CoreEntity<"roadmap"> &
  Archivable &
  Templatable &
  Locationable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    orders: Maybe<Orders>;
    body: Maybe<RichText>;
    period: Maybe<SelectOption>;
    owner: Maybe<Ref>;
    fields: Maybe<SortByProp[]>;
    views: Maybe<Ref[]>;
  };

export type Sprint = CoreEntity<"sprint"> &
  Templatable &
  Locationable &
  Archivable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    code: Maybe<string>;
    orders: Maybe<Orders>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    duration: Maybe<number>;
    status: Maybe<Status>;
    people: Maybe<Ref[]>;
    views: Maybe<Ref[]>;
  };

export type Page = CoreEntity<"page"> &
  Templatable &
  Locationable &
  Archivable & {
    icon: Maybe<URL>;
    title: Maybe<string>;
    body: Maybe<RichText>;
    owner: Maybe<Ref>;
    status: Maybe<Status>;
  };

export type Process = CoreEntity<"process"> &
  Templatable &
  Locationable &
  Archivable & {
    name: Maybe<string>;
    summary: Maybe<RichText>;
    body: Maybe<RichText>;
    owner: Maybe<Ref>;
    status: Maybe<Status>;
  };

export type Form = CoreEntity<"form"> &
  Templatable &
  Locationable &
  Archivable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    owner: Maybe<Ref>;
    purpose: Maybe<string>;
    entity: Maybe<EntityType>;
    useTemplate: Maybe<Ref>;
    overrides: Maybe<PropertyValueRef[]>;
    inLocation: Maybe<string>;
    fields: Maybe<FormPropDef[]>;
  };

export type Event = CoreEntity<"event"> &
  Templatable &
  Locationable &
  Archivable & {
    name: Maybe<string>;
    owner: Maybe<Ref>;
    start: Maybe<PointDate>;
    body: Maybe<RichText>;
    end: Maybe<PointDate>;
    status: Maybe<Status>;
  };

export type Request = CoreEntity<"request"> &
  Templatable &
  Locationable &
  Archivable & {
    title: Maybe<string>;
    owner: Maybe<Ref>;
    body: Maybe<RichText>;
    start: Maybe<PointDate>;
    end: Maybe<PointDate>;
    status: Maybe<Status>;
    assigned: Maybe<Ref>;
  };

export type Company = CoreEntity<"company"> &
  Templatable &
  Locationable &
  Archivable & {
    avatar: Maybe<URL>;
    name: Maybe<string>;
    body: Maybe<RichText>;
    websites: Maybe<Link[]>;
    industry: Maybe<SelectOption>;
    type: Maybe<SelectOption>;
    owner: Maybe<Ref>;
    orders: Maybe<Orders>;
  };

export type Contact = CoreEntity<"contact"> &
  Templatable &
  Locationable &
  Archivable & {
    avatar: Maybe<URL>;
    name: Maybe<string>;
    email: Maybe<string>;
    websites: Maybe<Link[]>;
    type: Maybe<SelectOption>;
    owner: Maybe<Ref>;
    body: Maybe<RichText>;
  };

export type Deal = CoreEntity<"deal"> &
  Templatable &
  Locationable &
  Archivable & {
    title: Maybe<string>;
    owner: Maybe<Ref>;
    value: Maybe<number>;
    status: Maybe<Status>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    body: Maybe<RichText>;
  };

export type Workflow = CoreEntity<"workflow"> &
  Templatable &
  Locationable &
  Archivable & {
    name: Maybe<string>;
    owner: Maybe<Ref>;
    status: Maybe<Status>;
    orders: Maybe<Orders>;
    inputs: Maybe<Vars>;
    options: Maybe<OptionsData>;
  };

export enum WorkflowAction {
  Create = "create",
  Update = "update",
  Wait = "wait",
  Find = "find",
  API = "api",
  Entry = "entry",
  Exit = "exit",
  Condition = "condition",
  RunWorkflow = "run_workflow",
  SetVar = "set_var",
  Control = "control",
  AI = "ai",
  AIStepGen = "ai_step_gen",
  Message = "message",
}

export type WorkflowStep = CoreEntity<"workflow_step"> &
  Templatable &
  Locationable &
  Archivable & {
    name: Maybe<string>;
    status: Maybe<Status>;
    action: Maybe<WorkflowAction>;
    owner: Maybe<Ref>;
    orders: Maybe<Orders>;
    condition: Maybe<FilterQuery>;
    inputs: Maybe<Vars>;
    outputs: Maybe<Vars>;
    options: Maybe<OptionsData>;
    overrides: Maybe<PropertyValueRef[]>;
  };

export type Pipeline = CoreEntity<"pipeline"> &
  Templatable &
  Locationable &
  Archivable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    owner: Maybe<Ref>;
    orders: Maybe<Orders>;
    stageBy: Maybe<PropertyRef>;
  };

export type KnowledgeBase = CoreEntity<"knowledgebase"> &
  Templatable &
  Locationable &
  Archivable & {
    icon: Maybe<URL>;
    name: Maybe<string>;
    owner: Maybe<Ref>;
  };

export type Meeting = CoreEntity<"meeting"> &
  Templatable &
  Locationable &
  Archivable & {
    name: Maybe<string>;
    purpose: Maybe<string>;
    summary: Maybe<RichText>;
    owner: Maybe<Ref>;
    status: Maybe<Status>;
    start: Maybe<PointDate>;
    duration: Maybe<number>;
    end: Maybe<PointDate>;
  };

export type Agenda = CoreEntity<"agenda"> &
  Templatable &
  Locationable &
  Archivable & {
    code: Maybe<string>;
    open: Maybe<boolean>;
    title: Maybe<string>;
    order: Maybe<number>;
    color: Maybe<Color>;
    body: Maybe<RichText>;
    notes: Maybe<RichText>;
  };

export type Action = CoreEntity<"action"> &
  Templatable &
  Locationable &
  Archivable & {
    title: Maybe<string>;
    status: Maybe<Status>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    open: Maybe<boolean>;
    assigned: Maybe<Ref>;
  };

export enum Period {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
}

export enum DayOfWeek {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export type Schedule = CoreDates &
  Templatable &
  Locationable &
  Archivable & {
    id: ID;
    name?: string;
    status: Maybe<Status>;
    from: Maybe<CalDate>;
    to: Maybe<CalDate>;
    last: Maybe<CalDate>;
    next: Maybe<CalDate>;
    entity: Maybe<EntityType>;
    useTemplate: Maybe<Ref>;
    overrides: Maybe<PropertyValueRef[]>;
    period: Period;
    frequency: number;
    precreate: number;
    daysOfPeriod: Maybe<number[]>;
    timeOfDay: Maybe<string>;
    timeZone: Maybe<string>;
    instances: Maybe<RelationRef[]>;
    source: DatabaseID<"schedule">;
  };

export type Task = CoreEntity<"task"> &
  Templatable &
  Locationable & {
    notionId: Maybe<ID>;
    source: DatabaseID<"task">;
    code: Maybe<ID>;
    title: Maybe<string>;
    status: Maybe<Status>;
    blocked: Maybe<boolean>;
    body: Maybe<RichText>;
    orders: Maybe<Orders>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    summary: Maybe<RichText>;
    assigned: Maybe<PersonRef | Ref>;
    checklist: Maybe<RichText>;
    links: Maybe<Link[]>;
  };

export type Outcome = CoreEntity<"outcome"> &
  Templatable &
  Locationable & {
    notionId: Maybe<ID>;
    code: Maybe<ID>;
    title: Maybe<string>;
    status: Maybe<Status>;
    blocked: Maybe<boolean>;
    body: Maybe<RichText>;
    orders: Maybe<Orders>;
    start: Maybe<ISODate>;
    end: Maybe<ISODate>;
    summary: Maybe<RichText>;
    assigned: Maybe<PersonRef | Ref>;
    links: Maybe<Link[]>;
  };

export interface View {
  id: ID;
  alias: Maybe<ID>;
  source: DatabaseID<"view">;
  name: string;
  icon: Maybe<URL>;
  order: Maybe<number>;

  entity: EntityType;
  layout: ViewLayout;
  grouping: Maybe<ViewGrouping>;
  filter?: FilterQuery;
  sort?: SortByProp[];
  group?: Maybe<GroupByProp<Entity>>[]; // Rename to groups?
  showProps?: PropertyRef<Entity>[];
  aggregate?: AggregatePropRef[];

  for?: RelationRef;
  team?: RelationRef;
  template: Maybe<Template>;
  location: ID;
  settings?: ViewOptions;

  fetchedAt: Maybe<ISODate>;
  createdAt: PointDate;
  createdBy: Maybe<PersonRef | Ref>;
  updatedAt: PointDate;
  updatedBy: Maybe<PersonRef | Ref>;
}

export enum NoteType {
  Note = "note",
  Update = "update",
  Discussion = "discussion",
}

export type Note = CoreEntity<"note"> &
  Locationable & {
    id: ID;
    type: NoteType;
    title: Maybe<string>;
    summary: Maybe<RichText>;
    body: Maybe<RichText>;
    author: Maybe<Ref>;
    pinned: Maybe<boolean>;
    links: Maybe<Link[]>;
  };

export enum ResourceType {
  Link = "link",
  File = "file",
}

export type Resource = CoreEntity<"resource"> &
  Locationable & {
    id: ID;
    type: ResourceType;
    source: DatabaseID<"resource">;
    name: Maybe<string>;
    url: Maybe<string>;
    icon: Maybe<URL>;
    mimeType: Maybe<string>;
    pinned: Maybe<boolean>;
  };

export type Database<E extends Entity> = {
  id: ID;
  source: Integration;
  type: EntityType | "object";
  props: PropertyDef<E>[];
  updatedAt: PointDate;
  createdAt: PointDate;
  fetchedAt: PointDate;
};

export enum TeamVisibility {
  Open = "open",
  Closed = "closed",
  Private = "private",
}

export type Team = CoreEntity<"team"> & {
  id: ID;
  notionId: Maybe<ID>;
  name: string;
  icon: Maybe<string>;
  color: Maybe<Color>;
  visibility: TeamVisibility;
  owner: Maybe<Ref>;
  parent: Maybe<Ref>;
  subTeams: Maybe<Ref[]>;
  views: Maybe<Ref[]>;
  people: Ref[];
  location: string;
};

export type SortByProp = {
  field: string;
  type: PropertyType;
  direction: "asc" | "desc";
  empty?: "first" | "last";
};

export type GroupByProp<E extends Entity> = {
  field: keyof E | string;
  type: PropertyType;
  format?: PropertyFormat;
  ordering?: string[];
  alwaysShow?: string[];
  alwaysHide?: string[];
  hideEmpty?: boolean;
  collapse?: boolean;
  sort?: "asc" | "desc";
  empty?: "first" | "last";
};

export type AggregatePropRef = {
  field: string;
  type: PropertyType;
  method: "sum" | "avg" | "count" | "min" | "max";
};

export type GroupByPropDef<
  E extends Entity,
  T extends PropertyType
> = GroupByProp<E> & {
  options?: PropertyDefOptions;
  values?: PropertyValues;
  scope?: string;
};

export type ConnectionRef = { id?: ID; field: string };

export type FormPropDef = {
  field: string;
  type: PropertyType;
  label?: string;
  description?: string;
  options?: PropertyDefOptions;
  connections?: ConnectionRef[];
  values?: PropertyValues;
};

export type PropertyMutationOperation = "set" | "clear" | "add" | "remove";

export type PropertyMutation<
  E extends Entity = Task,
  P extends PropertyType = PropertyType,
  K extends keyof E | string = keyof E | string
> = PropertyValueRef<E, P, K> & {
  op?: PropertyMutationOperation;
  prev?: PropertyValueRef<E, P, K>["value"];
};

// TODO: Convert to = Entity fallback
export type Update<T extends Entity = Task> = {
  id: ID;
  source: DatabaseID;
  queued?: Timestamp;
  persisted?: Timestamp;
  transaction?: ID;
  mode?: "temp" | "sync" | "workflow";
  attempt?: number;
  nextAttempt?: PointDate;
} & (
  | {
      method: "update";
      changes: PropertyMutation<T>[];
    }
  | {
      method: "apply";
      changes: PropertyMutation<T>[];
    }
  | {
      method: "create";
      changes: PropertyMutation<T>[];
    }
  | {
      method: "delete";
      previous: Maybe<T>;
    }
  | {
      method: "restore";
      restored: Maybe<T>;
    }
);

export type CreateOrUpdate<T extends Entity = Entity> = Extract<
  Update<T>,
  { changes: any }
>;

export type DeleteUpdate<T extends Entity> = Extract<
  Update<T>,
  { method: "delete" }
>;

export type RestoreUpdate<T extends Entity> = Extract<
  Update<T>,
  { method: "restore" }
>;

export enum JobStatus {
  Queued = "queued",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
}

export type Job = {
  id: ID;
  key: string;
  status: JobStatus;
  data: Maybe<JsonObject>;
  attempts: Maybe<number>;
  lockKey: Maybe<string>;
  lockedAt: Maybe<ISODate>;
  lockedBy: Maybe<Ref>;
  createdAt: PointDate;
  createdBy: Maybe<Ref>;
  updatedAt: PointDate;
  deletedAt: Maybe<PointDate>;
};

export type FilterOptions<E extends Entity> = Partial<E> & {
  mode?: "all" | "changed" | "ids";
  team?: Ref;
  person?: Ref;
  since?: ISODate;
  take?: number;
  skip?: number;
};

export type FilterOperation =
  | "does_not_contain"
  | "contains"
  | "does_not_equal"
  | "greater_than"
  | "less_than"
  | "equals"
  | "after"
  | "before"
  | "starts_with"
  | "ends_with"
  | "is_empty"
  | "is_not_empty"
  | "equals"
  | "before"
  | "after"
  | "on_or_before"
  | "on_or_after";

export type PropertyFilter = {
  field: string;
  type: PropertyType;
  op: FilterOperation;
  value?: Maybe<PropertyValue>;
  values?: Maybe<PropertyValues>;
  empty?: boolean;
};

export type FilterQuery<E extends Entity = Entity> =
  | PropertyFilter
  | { and: FilterQuery<E>[] }
  | { or: FilterQuery<E>[] };

export type CompoundFilterQuery<T extends Entity> = Extract<
  FilterQuery<T>,
  { and: any } | { or: any }
>;
export type SingleFilterQuery<T extends Entity = Task> = Exclude<
  FilterQuery<T>,
  CompoundFilterQuery<T>
>;

export type RichMarkup = PageBlocks;
export type { Block, BlockReference } from "./integrations/notion";

export type FlattenType<ArrType> =
  ArrType extends readonly (infer ElementType)[] ? ElementType : ArrType;

export type PageBody = {
  pageId: ID;
  source: Integration;
  data: RichMarkup;
};

import * as Sentry from "@sentry/nextjs";
import { isError, isString } from "lodash";

import { inLocal } from "./env";
import { Fn } from "./fn";
import { Maybe, safeAs } from "./maybe";

export const setUser = (user: { id: string; email?: string }) =>
  Sentry.setUser({ id: user.id, email: user.email });

// Doesn't log to sentry, can be left in for debugging
export const debug = <T>(inp: T, ...messages: (string | any)[]): T => {
  inLocal(() => console.log(inp, ...messages));
  return inp;
};

export const log = <T>(inp: T, message?: string | any): T => {
  debug(inp, message);

  Sentry.withScope((s) => {
    if (message) {
      s.setExtras(
        isString(message)
          ? { message }
          : isError(message) || !!safeAs<Error>(message)?.message
          ? { original: message }
          : message
      );
    }
    Sentry.captureException(inp);
  });

  return inp;
};

export const logCheckpoint = <T, R>(inp: T, fn: Fn<void, R>): R => {
  inLocal(() => console.log(inp));
  return fn();
};

export const addInfo = <T>(info: T): void => console.log(info);

export const warn = (message: string, extra?: Record<string, any>) => {
  log(message, extra);
  inLocal(() => {
    throw new Error(message);
  });
};

export const pause = <T>(inp?: T) => {
  debugger;
  return inp;
};

export const silence = async <R>(fn: Fn<void, R>): Promise<Maybe<R>> => {
  try {
    return await fn();
  } catch (err) {
    // Log error
    log(err);
    return undefined;
  }
};

export const logThrough = debug;

export const logStack = <T>(inp: T, ...messages: (string | any)[]): T => {
  try {
    console.log(inp, ...messages);
    throw new Error("Logging stack trace.");
  } catch (e) {
    console.log(e);
  }
  return inp;
};

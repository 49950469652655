import { useMemo } from "react";

import { ResourceType } from "@api";

import { isImage } from "@utils/url";

import {
  EmojiIcon,
  FileLandscape,
  ImageIcon,
  Notion,
  SlackColor,
} from "@ui/icon";

interface LinkProps {
  url?: string;
  icon?: string;
  type?: ResourceType;
}

const toDynamicFavicon = (url: string) =>
  `https://www.google.com/s2/favicons?domain=${url}`;

export const LinkIcon = ({ url, icon, type }: LinkProps) => {
  return useMemo(
    () =>
      url && isImage(url) ? (
        <ImageIcon url={url} />
      ) : icon?.startsWith("http") || icon?.startsWith("data") ? (
        <ImageIcon url={icon} />
      ) : !!icon && icon.length < 3 ? (
        <EmojiIcon emoji={icon} />
      ) : icon !== "never" && url?.includes("slack.") ? (
        <SlackColor />
      ) : icon !== "never" && url?.includes("notion.") ? (
        <Notion />
      ) : type === "file" ? (
        <FileLandscape />
      ) : !!url ? (
        <ImageIcon url={toDynamicFavicon(url)} />
      ) : (
        <FileLandscape />
      ),
    [url, icon]
  );
};

import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLError } from "graphql";
import { map } from "lodash";

import { GraphError } from "@api/types";

import { omitEmpty, pushDirty } from "@utils/array";
import { log } from "@utils/debug";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { graphql as gql } from "./gen/gql";

let auth: Maybe<string> = undefined;

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (auth) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${auth}`,
      },
    }));
  }

  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, operation) =>
      !!error &&
      // Lol error is not {message} but some {result: {errors: [{message}]}} structure
      // Didn't know type so just stringifying
      !when(
        JSON.stringify(error),
        (str) =>
          str.includes(GraphError.PermissionDenied) ||
          str.includes(GraphError.BadAuthorization)
      ),
  },
});

const logErrors = onError(({ graphQLErrors, networkError }) => {
  map(graphQLErrors, log);

  if (networkError) {
    // handle network error
    log(networkError);
  }
});

// Notify all observers
const observers: Fn<GraphQLError | NetworkError, void>[] = [];
const observeErrors = onError(({ graphQLErrors, networkError }) => {
  map(observers, (obs) =>
    map(omitEmpty([networkError, ...(graphQLErrors || [])]), obs)
  );
});
export const addErrorObserver = (
  obs: Fn<GraphQLError | NetworkError, void>
) => {
  pushDirty(observers, obs);
};
export const removeErrorObserver = (
  obs: Fn<GraphQLError | NetworkError, void>
) => {
  observers.splice(observers.indexOf(obs), 1);
};

const httpLink = new HttpLink({ uri: "/api/graphql" });

const client = new ApolloClient({
  link: from([logErrors, observeErrors, retryLink, authLink, httpLink]),
  cache: new InMemoryCache({
    // Required for GQL – Update on new entity
    // TODO: Potentially remove this when we have a better way to handle
    possibleTypes: {
      Fetchable: [
        "Error",
        "Person",
        "Task",
        "Outcome",
        "Page",
        "Campaign",
        "Calendar",
        "Content",
        "Backlog",
        "Roadmap",
        "Sprint",
        "Schedule",
        "Project",
        "Meeting",
        "Agenda",
        "Action",
        "Team",
        "View",
        "Note",
        "Resource",
        "Process",
        "Form",
        "Event",
        "Request",
        "Pipeline",
        "Workflow",
        "WorkflowStep",
        "KnowledgeBase",
        "Workspace",
      ],
    },
    typePolicies: {
      Link: {
        keyFields: ["url"],
      },
    },
  }),
});

export const setTractionToken = (token: string) => {
  auth = token;
};

export const mutate: typeof client.mutate = (opts) =>
  client
    .mutate({
      errorPolicy: "all",
      ...opts,
    })
    .then((res) => {
      if (res.errors) {
        throw res.errors?.length > 1 ? res.errors : res.errors[0];
      }
      return res;
    });

// Expose appolo query func
export const query: typeof client.query = (opts) =>
  client
    .query({
      errorPolicy: "all",
      fetchPolicy: "network-only",
      ...opts,
    })
    .then((res) => {
      if (res.errors) {
        throw res.errors?.length > 1 ? res.errors : res.errors[0];
      }
      return res;
    });

// Expose gql typed builder
export { gql };

import { Primitive, SafeRecord } from "./types";

// Core data types that are both used in the client and server

export type Ref = { id: ID; name?: string };
export type RefOrID = Ref | ID;

export type RefsData = SafeRecord<string, Ref[]>;
export type CustomData = SafeRecord<
  string,
  Primitive | Ref | Ref[] | RichText | Json
>;
export type SettingsData = SafeRecord<string, Primitive>;
export type OptionsData = SafeRecord<string, Primitive | RichText>;
export type Orders = SafeRecord<string, string | number>;

export type ID = string;
export type Timestamp = number;
export type EpochDate = number;

export type RichText = {
  text?: string;
  markdown?: string;
  html?: string;
};

export type Stamp = { by: ID; at: EpochDate };
export type Stamps = SafeRecord<string, Stamp>;

export type JsonValue =
  | null
  | string
  | Date
  | number
  | boolean
  | JsonObject
  | JsonArray;
export interface JsonObject {
  [x: string]: JsonValue | undefined;
}
export type JsonArray = JsonValue[];
export type Json = JsonArray | JsonObject;

type ColorOrShade<T extends string> =
  | T
  | `${T}_2`
  | `${T}_3`
  | `${T}_4`
  | `${T}_5`;

export type Shade = 1 | 2 | 3 | 4 | 5;

export type BaseColor =
  | "red"
  | "blue"
  | "gray"
  | "orange"
  | "purple"
  | "green"
  | "turquoise"
  | "brown"
  | "yellow"
  | "pink";

export type Color = ColorOrShade<BaseColor>;

export type Template = "root" | "nested";

export enum EntityTypeEnum {
  task = "task",
  outcome = "outcome",
  campaign = "campaign",
  calendar = "calendar",
  content = "content",
  project = "project",
  page = "page",
  backlog = "backlog",
  roadmap = "roadmap",
  sprint = "sprint",
  schedule = "schedule",
  view = "view",
  team = "team",
  note = "note",
  resource = "resource",
  meeting = "meeting",
  agenda = "agenda",
  action = "action",
  person = "person",
  process = "process",
  form = "form",
  request = "request",
  event = "event",
  pipeline = "pipeline",
  workflow = "workflow",
  workflow_step = "workflow_step",
  knowledgebase = "knowledgebase",
  company = "company",
  contact = "contact",
  deal = "deal",
  workspace = "workspace",
}

export type EntityType = keyof typeof EntityTypeEnum;

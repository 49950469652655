import copy from "copy-to-clipboard";
import { differenceInHours } from "date-fns";
import { initial, isArray, isString, reduce } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import {
  NavigateOptions as _NavigateOptions,
  Path,
  useLocation,
  useNavigate as pureUseNavigate,
} from "react-router-dom";

import {
  Entity,
  EntityType,
  ID,
  isResource,
  RelationRef,
  Resource,
} from "@api";

import { ensureMany, OneOrMany } from "./array";
import { getEnvVar } from "./env";
import { fallback } from "./fn";
import { maybeTypeFromId } from "./id";
import { HOST_ORIGIN, isWeb } from "./link";
import { equalsAny, ifDo_, switchEnum } from "./logic";
import { Maybe, safeAs, when } from "./maybe";
import { now } from "./now";
import { redirect, toOrigin, withParams } from "./url";

interface Entityish {
  id: string;
  source: { type: EntityType };
}

export type NavigateOptions = _NavigateOptions & { newTab?: boolean };

type Navigatable =
  | Entityish
  | Extract<Entity, { source: { type: EntityType } }>
  | RelationRef
  | { id: string }
  | { id: ID }
  | string;

const LOADED = now();

const shouldReload = () => differenceInHours(now(), LOADED) > 1;

export const usePathName = () => {
  const location = useLocation();
  // Return the window pathname as nested routes don't return the whole path
  return useMemo(() => window.location.pathname, [location]);
};

export function useNavigate() {
  const navigate = pureUseNavigate();

  return useCallback(
    (url: string | Partial<Path>, opts?: NavigateOptions) => {
      if (shouldReload()) {
        const toRedirect = isString(url)
          ? url
          : when(
              url?.toString() || "/",
              (path) => toOrigin(window.location.href) + path
            );

        if (!toRedirect) {
          return;
        }

        return redirect(toRedirect, opts?.newTab);
      }

      if (isString(url) && (isWeb(url) || opts?.newTab)) {
        return redirect(url, opts?.newTab);
      }

      return navigate(url, opts);
    },
    [navigate]
  );
}

export const baseUrl = () =>
  (typeof window !== "undefined" ? window.location.origin : undefined) ||
  getEnvVar("HOST") ||
  HOST_ORIGIN;

const toUrlPart = (ref: Entityish | RelationRef) =>
  switchEnum(
    (ref as Maybe<Entityish>)?.source?.type ||
      maybeTypeFromId(ref.id) ||
      (ref as Maybe<RelationRef>)?.entity ||
      "",
    {
      else: () => "/" + ref.id,
    }
  );

export const toUrl = (
  refs: OneOrMany<Navigatable>,
  params?: Record<string, string>
) =>
  withParams(
    reduce(
      ensureMany(refs),
      (url, ref) => {
        if (ref === "/") {
          return url;
        }

        if (isString(ref)) {
          return url + (ref?.startsWith("/") ? ref : "/" + ref);
        }

        if (isWeb(url) || !ref) {
          return url;
        }

        const part = toUrlPart(ref);
        if (isWeb(part)) {
          return part;
        }

        return url + part;
      },
      ""
    ),
    params
  );

export const toLink = (ref: Entity | Entityish | RelationRef | { id: ID }) =>
  fallback(
    ifDo_(isResource(ref), () => safeAs<Resource>(ref)?.url),
    () => baseUrl() + toUrlPart(ref)
  );

export const home = () => "/";

// Hooks

export const useNavigateModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return useCallback(
    (to: string | Partial<Path>) =>
      navigate(to, {
        state: {
          modal: true,
          return: location.state?.modal
            ? location.state?.return
            : location?.pathname,
        },
      }),
    [location]
  );
};

// Goes directly to the path/entity, as a new stack
export const useGoTo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (
      t: OneOrMany<Navigatable>,
      params?: Record<string, string>,
      opts?: NavigateOptions
    ) => {
      const newUrl = isArray(t)
        ? toUrl(t, params)
        : !isString(t)
        ? toUrl(t, params)
        : t;

      // External redirect
      if (newUrl?.startsWith("http")) {
        return redirect(newUrl, opts?.newTab ?? true);
      }

      // Just normal navigate
      return navigate(newUrl, opts);
    },
    [location?.pathname]
  );
};

export const useGoBack = () => {
  const goTo = useGoTo();
  const location = useLocation();
  const returnPath = useMemo(
    () => initial(location?.pathname?.split("/"))?.join("/"),
    [location]
  );

  const goBack = useCallback(() => goTo(returnPath), [returnPath]);
  const canGoBack = !!returnPath;
  return { goBack, canGoBack, returnPath };
};

export const useBrowserBack = () => {
  const router = useRouter();
  return useCallback(() => {
    router?.back();
  }, [router]);
};

// Goes to the path/entity, but replaces the current stack
export const useReplace = () => {
  const navigate = useNavigate();
  return useCallback(
    (t: OneOrMany<Navigatable>, params?: Record<string, string>) =>
      navigate(toUrl(t, params), { replace: true }),
    []
  );
};

// Pushes the path/entity onto the stack
export const usePushTo = () => {
  const goTo = useGoTo();
  const currentPath = usePathName();
  return useCallback(
    (t: OneOrMany<Navigatable>, params?: Record<string, string>) => {
      const newPart = toUrl(t, params);

      // Loop detected
      if (
        currentPath?.includes(newPart) &&
        !equalsAny(newPart, ["/", "/builder"])
      ) {
        goTo(
          currentPath?.substring(
            0,
            currentPath?.indexOf(newPart) + newPart?.length
          ),
          params
        );
      } else {
        goTo([currentPath, ...ensureMany(t)], params);
      }
    },
    [goTo, currentPath]
  );
};

// Pushes the path/entity onto the stack
export const useGoUp = () => {
  const goTo = useGoTo();
  const pathName = usePathName();
  return useCallback(() => {
    const existing = pathName.split("/");

    // Remove last part
    existing.pop();

    goTo(existing?.join("/") || "/home");
  }, [goTo, pathName]);
};

export const useCopyLink = () => {
  return useCallback((t: Entityish | RelationRef) => copy(toLink(t)), []);
};

import { first, map, omit } from "lodash";

import { Maybe, required, safeAs, when } from "@utils/maybe";
import { omitEmpty } from "@utils/object";

import type {
  DatabaseID,
  Entity,
  Error,
  ID,
  Job,
  JobStatus,
  Json,
  Person,
  PropertyDef,
  PropertyRef,
  PropertyType,
  PropertyValues,
  Update,
} from "../../types";
import { Integration } from "../../types";
import * as api from "./graph/api";
import * as Graph from "./graph/types";
import { Tag } from "./graph/types";
import * as mappings from "./mappings";

const process = async (updates: Graph.UpdateInput[]) => {
  const { data } = await api.mutate({
    mutation: api.gql(/* GraphQL */ `
      mutation ProcessUpdates($updates: [UpdateInput!]) {
        process(updates: $updates) {
          items
        }
      }
    `),
    variables: { updates },
  });

  return (data?.process?.items || []) as Graph.Fetchable[];
};

const processOne = async (u: Graph.UpdateInput) => first(await process([u]));

const mapResult = <T extends Entity>(result: Graph.Fetchable): Entity | Error =>
  mappings.mapFetchable(result);

const toSource = (source: DatabaseID) => source.source || Integration.Traction;

export const persist = async <T extends Entity>(
  updates: Update<T>[]
): Promise<(Entity | Error)[]> => {
  return map(await process(map(updates, mappings.toAPIUpdate)), (r) =>
    mapResult(r)
  );
};

export const persistNew = async <T extends Entity>(
  update: Extract<Update<T>, { method: "create" }>
) => {
  if (toSource(update.source) === "traction") {
    const r = await processOne(mappings.toAPIUpdate(update));
    return mapResult(required(r, () => "Create update returned no result."));
  }

  throw new Error("Unsupported update integration.");
};

export const persistUpdate = async <T extends Entity>(
  update: Extract<Update<T>, { method: "update" }>
) => {
  if (toSource(update.source) === "traction") {
    return when(await processOne(mappings.toAPIUpdate(update)), mapResult);
  }

  throw new Error("Unsupported update integration.");
};

export const persistRestore = async <T extends Entity>(
  update: Extract<Update<T>, { method: "restore" }>
) => {
  if (toSource(update.source) === "traction") {
    return when(await processOne(mappings.toAPIUpdate(update)), mapResult);
  }

  throw new Error("Unsupported update integration.");
};

export const persistDelete = async <T extends Entity>(
  update: Extract<Update<T>, { method: "delete" }>
) => {
  if (toSource(update.source) === "traction") {
    await processOne(mappings.toAPIUpdate(update));
    return undefined;
  }

  throw new Error("Unsupported update integration.");
};

export const authenticate = (code: string, source: Integration) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation SSOAuthenticate($code: String!, $source: Integration!) {
          authenticate(sso: { source: $source, token: $code, scope: person }) {
            ...SessionFragment
          }
        }
      `),
      variables: { code, source: source as Graph.Integration },
    })
    .then(({ data }) =>
      when(data?.authenticate, mappings.toClientWorkspaceConfig)
    );

export const authorize = (
  code: string,
  source: Integration,
  scope: Graph.AuthScope
) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation SSOAuthorize(
          $code: String!
          $source: Integration!
          $scope: AuthScope!
        ) {
          authorize(
            integration: { source: $source, token: $code, scope: $scope }
          ) {
            ...SessionFragment
          }
        }
      `),
      variables: { code, source: source as Graph.Integration, scope },
    })
    .then(({ data }) =>
      when(data?.authorize, mappings.toClientWorkspaceConfig)
    );

export const createWorkspace = (name: string) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation CreateWorkspace($name: String!) {
          createWorkspace(name: $name) {
            ...SessionFragment
          }
        }
      `),
      variables: { name },
    })
    .then(({ data }) =>
      when(data?.createWorkspace, mappings.toClientWorkspaceConfig)
    );

export type PropertyDefChanges<
  E extends Entity,
  T extends PropertyType
> = Partial<PropertyDef<E, T>> & {
  values?: PropertyValues &
    Partial<{
      set: PropertyValues[T];
      add: PropertyValues[T];
      remove: PropertyValues[T];
      update: PropertyValues[T];
    }>;
};

export const updatePropertyDef = <E extends Entity, T extends PropertyType>(
  db: DatabaseID,
  ref: PropertyRef<E, T>,
  changes: PropertyDefChanges<E, T>
) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation UpdatePropertyDef(
          $scope: PropertyScope!
          $ref: PropertyRefInput!
          $changes: PropertyDefInput!
        ) {
          updatePropertyDef(scope: $scope, def: $ref, changes: $changes) {
            ...PropertyDefFragment
          }
        }
      `),
      variables: {
        scope: { entity: db.type as Graph.EntityType, scope: db.scope },
        ref: {
          field: ref.field as string,
          type: ref.type as Graph.PropertyType,
        },
        changes: {
          // Non-nullable fields
          ...omitEmpty({
            entity: changes.entity as Maybe<Graph.EntityType[]>,
            label: changes.label,
            order: changes.order,
            options: changes.options,
            type: changes.type as Maybe<Graph.PropertyType>,
            visibility: changes.visibility,
            locked: changes.locked,
            assist: changes.assist,
            readonly: changes.readonly,
            values: (changes.values?.set ||
              changes.values?.[ref.type]) as Tag[],
            addValues: changes.values?.add as Tag[],
            removeValues: changes.values?.remove as Tag[],
            updateValues: changes.values?.update as Tag[],
          }),

          // Allow emptying out format field with undefined/null
          format: changes.format as Maybe<Graph.PropertyFormat>,
        },
      },
    })
    .then(({ data }) =>
      when(data?.updatePropertyDef, (d) =>
        mappings.toClientPropertyDef<E, T>(d)
      )
    );

export const createPropertyDef = <E extends Entity, T extends PropertyType>(
  db: DatabaseID,
  ref: PropertyRef<E, T>,
  changes: Partial<PropertyDef<E, T>>
) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation CreatePropertyDef(
          $scope: PropertyScope!
          $ref: PropertyRefInput!
          $changes: PropertyDefInput
        ) {
          createPropertyDef(scope: $scope, def: $ref, changes: $changes) {
            ...PropertyDefFragment
          }
        }
      `),
      variables: {
        scope: { entity: db.type as Graph.EntityType, scope: db.scope },
        ref: {
          field: ref.field as string,
          type: ref.type as Graph.PropertyType,
        },
        changes: {
          ...omit(changes, "values", "value", "type", "system", "format"),
          // Client/Server Enum castings
          format: changes.format as Maybe<Graph.PropertyFormat>,
          entity: changes.entity as Maybe<Graph.EntityType[]>,
          values: changes.values?.[ref.type] as Maybe<Graph.TagInput[]>,
        },
      },
    })
    .then(({ data }) =>
      when(data?.createPropertyDef, (d) =>
        mappings.toClientPropertyDef<E, T>(d)
      )
    );

export const deletePropertyDef = <E extends Entity, T extends PropertyType>(
  source: DatabaseID,
  ref: PropertyRef<E, T>
) =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation DeletePropertyDef(
          $scope: PropertyScope!
          $ref: PropertyRefInput!
        ) {
          deletePropertyDef(scope: $scope, def: $ref) {
            ...PropertyDefFragment
          }
        }
      `),
      variables: {
        scope: { entity: source.type as Graph.EntityType, scope: source.scope },
        ref: {
          field: ref.field as string,
          type: ref.type as Graph.PropertyType,
        },
      },
    })
    .then(({ data }) =>
      when(data?.deletePropertyDef, (d) =>
        mappings.toClientPropertyDef<E, T>(d)
      )
    );

export const updateJobStatus = (
  id: ID,
  status: JobStatus
): Promise<Maybe<Job>> => {
  return api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation UpdateJobStatus($id: ID!, $status: JobStatus!) {
          updateJob(id: $id, status: $status) {
            ...JobFragment
          }
        }
      `),
      variables: { id, status },
    })
    .then(({ data }) => when(data?.updateJob, mappings.toClientJob));
};

export const claimJob = (id: ID, lockKey: string): Promise<Maybe<Job>> => {
  return api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation ClaimJob($id: ID!, $lockKey: ID!) {
          claimJob(id: $id, lockKey: $lockKey) {
            ...JobFragment
          }
        }
      `),
      variables: { id, lockKey },
    })
    .then(({ data }) => when(data?.claimJob, mappings.toClientJob));
};

export const joinWaitlist = (
  email: string,
  options: Json
): Promise<Maybe<{ email: string; position: number; data: Json }>> => {
  return api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation JoinWaitlist($email: String!, $options: JSON) {
          join(email: $email, data: $options) {
            email
            position
            data
          }
        }
      `),
      variables: { email, options },
    })
    .then(({ data }) =>
      when(data?.join, ({ email, position, data }) => ({
        email,
        position,
        data,
      }))
    );
};

export const addToWorkspace = (
  people: Partial<Person>[]
): Promise<Maybe<Person[]>> =>
  api
    .mutate({
      mutation: api.gql(/* GraphQL */ `
        mutation AddToWorkspace($people: [PersonInviteInput!]) {
          addToWorkspace(people: $people) {
            ...PersonFragment
          }
        }
      `),
      variables: {
        people: map(people, (p) => ({
          email: required(p.email, () => "Email is required."),
          role: safeAs<Graph.PersonRole>(p.role?.id) || Graph.PersonRole.Member,
          name: p.name,
          aka: p.aka,
          avatar: p.avatar,
        })),
      },
    })
    .then(({ data }) =>
      map(data?.addToWorkspace, (p) => mappings.toClientPerson(p))
    );
